@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RobotoRegular.ttf");
  font-family: "Roboto";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RobotoMedium.ttf");
  font-family: "Roboto";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RobotoRomanBold.ttf");
  font-family: "Roboto";
  font-weight: 700;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/RobotoBold.ttf");
  font-family: "Roboto";
  font-weight: 700;
}
