/* Custom Scrollbar Styles */
.scrollbar-custom::-webkit-scrollbar {
    width: 3px; /* Width of the scrollbar */
  }
  
  .scrollbar-custom::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }
  
  .scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: #cacacc; /* Scrollbar thumb color */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 2px solid #f1f1f1; /* Optional: Border around the thumb */
  }
  
  